angular.module('app')
    .factory('Excel', ["$window", function ($window) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            csvUri = 'data:application/csv;charset=utf-8,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) {
                return $window.btoa(unescape(encodeURIComponent(s))
                );
            },
            format = function (template, context) {
                return template.replace(/{(\w+)}/g, function (m, p) {
                    return context[p];
                });
            },
            // Temporary delimiter characters unlikely to be typed by keyboard
            // This is to avoid accidentally splitting the actual contents
            tmpColDelim = String.fromCharCode(11),
            tmpRowDelim = String.fromCharCode(0),
            colDelim = '","',
            rowDelim = '"\r\n"';

        function formatRows(rows) {
            return rows.get().join(tmpRowDelim)
                .split(tmpRowDelim).join(rowDelim)
                .split(tmpColDelim).join(colDelim);
        }

        // Grab and format a row from the table
        function grabRow(i, currRow) {

            var row = angular.element(currRow);
            //for some reason cols = row.find('td') || row.find('th') won't work...
            var cols = row.find('td');
            if (!cols.length) cols = row.find('th');
            return cols.map(grabCol)
                .get().join(tmpColDelim);
        }

        function grabCol(j, col) {
            var text = angular.element(col).text();
            return text.replace('"', '""'); // escape double quotes
        }

        return {
            tableToExcel: function (tableId, worksheetName) {
                var table = angular.element(tableId),
                    ctx = {
                        worksheet: worksheetName,
                        table: table.html()
                    };

                href = uri + base64(
                        format(template, ctx)
                    );
                return href;
            },
            tableToCsv: function (tableId) {

                var table = angular.element(tableId);
                var headers = table.find('tr:has(th)');
                var rows = table.find('tr:has(td)');

                // Grab text from table into CSV formatted string
                var csv = '"';
                csv += formatRows(headers.map(grabRow));
                csv += rowDelim;
                csv += formatRows(rows.map(grabRow)) + '"';

                // Data URI
                var href = csvUri + encodeURIComponent(csv);

                return href;
            }
        };
    }]);